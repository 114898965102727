import React from "react";
import {graphql} from "gatsby";
import {SliceMachine} from "@HichiHachi/react-slices";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Sidebar, Landing } from "components";
import linkResolver from "utils/linkresolver";
import Seo from "utils/seo";
import {GetSocials} from "utils/convertData";

export const query = graphql`
  {
    prismicPage(uid: {eq: "homepage"}){
      _previewable
      first_publication_date
      data {
        ...pageQuery
      }
    }
    prismicNavigation {
        data {
          ...socialQuery
        }
    }
    prismicSidebar {
      ...sidebarFragment
    }
  }
`;

const IndexPage = ({data}) => {
  const page = data?.prismicPage?.data;
  const slices = page?.body;
  const sidebar = page?.sidebar;

  const sidebarData = data?.prismicSidebar?.data;

  const social = GetSocials(data);
  
	const context = {social}

  const customSlices = [
    {type: "landing", component: Landing}
  ]

  return (
    <>
      <Seo title={page.meta_title} description={page.meta_description} image={page.meta_image}/>
      {!!sidebar && <Sidebar info={sidebarData}/>}
      <SliceMachine body={slices} context={context} customSlices={customSlices} customLinkResolver={linkResolver}/>
    </>
  )

}

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_SITE_REPO,
    linkResolver,
  },
])