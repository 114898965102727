const linkResolver = (doc) => {
	// URL for a category type
	if (doc.type === 'blog_post') {
		return `/blog/${doc.uid}`;
	}

	// URL for a page type
	if (doc.type === 'page') {
		return !!(doc.uid !== "homepage") ? `/${doc.uid}` : `/`;
	}

	// URL for external link
	if(doc.link_type === "Web"){
		return {external: true, url: doc.url};
	}

	if(doc.type === 'sidebar') {
		return `/design-document`;
	}

	// Backup for all other types
	return '/';
};

module.exports = linkResolver;