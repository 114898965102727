import {FaFacebookF, FaTwitter, FaLinkedin, FaPinterest, FaInstagram, FaYoutube} from "react-icons/fa";

export const GetSocials = (data) => {
    const navigation = data?.prismicNavigation?.data;

    const fb = navigation?.facebook;
	const li = navigation?.linkedin;
	const tw = navigation?.twitter;
	const pin = navigation?.pinterest;
	const ytb = navigation?.youtube;
	const insta = navigation?.instagram;

    return [
		{name: "facebook", link: fb, icon: FaFacebookF},
		{name: "linkedin", link: li, icon: FaLinkedin}, 
		{name: "twitter", link: tw, icon: FaTwitter},
		{name: "pinterest", link: pin, icon: FaPinterest},
		{name: "youtube", link: ytb, icon: FaYoutube},
		{name: "instagram", link: insta, icon: FaInstagram}
	];
}