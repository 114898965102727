import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, image: metaImage }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						image
					}
				}
			}
		`
	);

	const metaTitle = title || site.siteMetadata.title;
	const metaDescription = description || site.siteMetadata.description;
	const image = metaImage ? `${metaImage.url}` : `${site.siteMetadata.image}`;

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={metaTitle}
			titleTemplate={`%s`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			]
				.concat(
					image
						? [
								{
									property: 'og:image',
									content: image
								},
								{
									property: 'og:image:width',
									content: metaImage?.width || true
								},
								{
									property: 'og:image:height',
									content: metaImage?.height || true
								},
								{
									name: 'twitter:card',
									content: 'summary_large_image'
								}
							]
						: [
								{
									name: 'twitter:card',
									content: 'summary'
								}
							]
				)
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired
};

export default SEO;
